import { SITE_NAME, WEB_ADDRESS } from 'src/config/site'
import { ReactNode } from 'react'
import Head from 'next/head'

interface IProps {
  title: string
  path?: string
  description?: string
  children?: ReactNode
}

function Metadata({ title, path = '/', description = 'Quick Organics', children }: IProps) {
  const url = `${WEB_ADDRESS}${path}`
  return (
    <Head>
      {/* metainfo */}
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover' />
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content='quick organics' />
      <meta name='robots' content='index, follow' />

      {/* favicons */}
      <link rel='manifest' href='/manifest.json' />
      <link rel='shortcut icon' href='/favicon.ico' />
      <link rel='icon' type='image/png' sizes='32x32' href='/icons/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/icons/favicon-16x16.png' />
      <link rel='mask-icon' href='/icons/safari-pinned-tab.svg' color='#5bbad5' />
      <link rel='canonical' href={WEB_ADDRESS} />
      <meta name='msapplication-TileColor' content='#2b5797' />
      <meta name='msapplication-config' content='/icons/browserconfig.xml' />
      <meta name='theme-color' content='#ffffff' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
      <meta name='format-detection' content='telephone=no' />
      <meta name='mobile-web-app-capable' content='yes' />
      <meta name='msapplication-tap-highlight' content='no' />
      <meta name='apple-mobile-web-app-title' content={SITE_NAME} />
      <meta name='application-name' content={SITE_NAME} />
      <link rel='apple-touch-icon' href='/icons/apple-touch-icon.png' />
      <link rel='apple-touch-icon' sizes='180x180' href='/icons/apple-touch-icon.png' />
      <link rel='apple-touch-icon' sizes='167x167' href='/icons/touch-icon-ipad-retina.png' />
      <link rel='apple-touch-icon' sizes='152x152' href='/icons/touch-icon-ipad.png' />

      {/* open graphs */}
      <meta property='og:title' content={title} />
      <meta property='og:url' content={url} />
      <meta property='og:type' content='website' />
      <meta property='og:locale' content='en_US' />
      <meta property='og:description' content={description} />
      <meta property='og:image' content='/logo.png' />
      <meta property='og:site_name' content={SITE_NAME} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content='/logo.png' />
      <meta name='twitter:image:alt' content='twitter image' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:url' content={url} />
      <meta name='twitter:creator' content='@quickorganics' />

      {/* apple splash screen images */}
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2048-2732.jpg'
        media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2732-2048.jpg'
        media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1668-2388.jpg'
        media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2388-1668.jpg'
        media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1536-2048.jpg'
        media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2048-1536.jpg'
        media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1668-2224.jpg'
        media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2224-1668.jpg'
        media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1620-2160.jpg'
        media='(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2160-1620.jpg'
        media='(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1284-2778.jpg'
        media='(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2778-1284.jpg'
        media='(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1170-2532.jpg'
        media='(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2532-1170.jpg'
        media='(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1125-2436.jpg'
        media='(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2436-1125.jpg'
        media='(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1242-2688.jpg'
        media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2688-1242.jpg'
        media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-828-1792.jpg'
        media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1792-828.jpg'
        media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1242-2208.jpg'
        media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-2208-1242.jpg'
        media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-750-1334.jpg'
        media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1334-750.jpg'
        media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-640-1136.jpg'
        media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='splash/apple-splash-1136-640.jpg'
        media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
      />

      {children}
    </Head>
  )
}

export default Metadata
